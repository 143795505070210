import React, {useEffect, useState} from "react"
import Config from '../config'

import Container from "../components/container"
import BlogHeader from "../components/blogHeader"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Footer from "../components/footer"

const YOUTUBE = "youtube"

const BlogPageTemplate = ({ pageContext }) => {
  const { node } = pageContext
  const [data, setData] = useState({})

  const getData = async stringID => {
    const id = stringID.match(/(\d+)/)[0]
    const call = await fetch(
      `${Config.apiUrl}/items/blogs/${id}?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setData(response.data)
  }

  useEffect(() => {
    getData(node.id)
  }, [node])

  return (
    <>
      <SEO
        title={node.meta_title}
        desc={node.meta_desc}
        keywords={node.meta_keywords}
        ogTitle={node.og_title}
        ogDesc={node.og_desc}
        ogImage={node.og_image?.data.full_url}
      />

      <Container>
        <Navbar />

        <BlogHeader
          image={data.banner?.data.full_url}
          title={data.title}
          subtitle={data.subtitle}
          type={data.type}
          source={data.type === YOUTUBE ? data.link : data.file?.data.full_url}
        />

        <main id="main">
          <section id="about" className="about">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 content">
                  <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default BlogPageTemplate
