import React, { useState } from "react"
import styled from "styled-components"
import ModalVideo from "react-modal-video"

import "react-modal-video/css/modal-video.min.css"

const YOUTUBE = "youtube"

const Section = styled.section`
  width: 100%;
  height: 60vh;
  background: url("${props => props.image}") top center;
  background-size: cover;
  position: relative;
  padding: 0;
`

const BlogHeader = ({ image, title, subtitle, type, source }) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <>
      <Section image={image} id="hero" className="d-flex align-items-center">
        <div className="container position-relative text-center text-lg-start">
          <div className="row">
            <div className="col-lg-8">
              {title && <h1>{title}</h1>}
              {subtitle && <h2>{subtitle}</h2>}
            </div>
            {type && source && (
              <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative">
                <div
                  className="glightbox play-btn"
                  onClick={() => setOpen(true)}
                  role="button"
                  onKeyDown={() => setOpen(true)}
                  tabIndex={0}
                >
                  <span aria-hidden></span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Section>
      {type && source && type === YOUTUBE ? (
        <ModalVideo
          channel="youtube"
          autoplay
          videoId={source}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
      ) : (
        <ModalVideo
          channel="custom"
          autoplay
          url={source}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default BlogHeader
